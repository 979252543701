import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide68/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide68/image/img.png"
import slider1 from "@components/pageGuide/guides/guide68/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide68/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide68/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      6 casos de uso de la IA para aumentar las ventas de una concesionaria
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Desde la personalización de ofertas hasta la automatización de procesos de marketing y ventas, la IA no solo ayuda a mejorar la eficiencia, sino que también permite generar una experiencia más atractiva y coherente para el cliente.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const textOne = (
  <p>
    Desde la personalización de ofertas hasta la automatización de procesos de marketing y ventas, la IA no solo ayuda a mejorar la eficiencia, sino que también permite generar una experiencia más atractiva y coherente para el cliente.
    <br /><br />
    ¿Estás listo para descubrir cómo la IA puede ayudar a tu concesionaria a cerrar más ventas y fidelizar a tus clientes? Aquí te presentamos seis formas en las que puedes aprovechar al máximo esta tecnología innovadora.
  </p>
)

const data = {
  start: {
    support: "Guía | Concesionarias",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Personalización de ofertas y recomendaciones automáticas.",
      },
      {
        key: 2,
        text: "Automatización de la prospección de leads.",
      },
      {
        key: 3,
        text: "Optimización de campañas de marketing digital.",
      },
      {
        key: 4,
        text: "Chatbots para mejorar la atención al cliente.",
      },
      {
        key: 5,
        text: "Previsión de la demanda con IA.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
